
import Pagination from '@/components/pagination/Pagination.vue';
import Vue from 'vue';
import { Component, PropSync, Watch } from 'vue-property-decorator';
import TPagination from './pagination';

@Component({
  components: {
    Pagination,
    TPagination,
  },
})
export default class TableComponent extends Vue {
  @PropSync('data') dataSync!: any;

  @Watch('data')
  bookingHandler(): void {
    this.setPages();
  }

  get entityList() {
    return this.paginate(this.dataSync);
  }

  mounted(): void {
    this.setPages();
  }

  setPages(): void {
    const numberOfPages = Math.ceil(this.dataSync.length / this.perPage);
    for (let index = 1; index <= numberOfPages; index += 1) {
      this.pages.push(index);
    }
  }

  paginate(data: string | never[]): string | never[] {
    const { page } = this;
    const { perPage } = this;
    const from = page * perPage - perPage;
    const to = page * perPage;
    return data.slice(from, to);
  }

  posts = [''];

  page = 1;

  perPage = 10;

  pages: number[] = [];
}
