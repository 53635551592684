
import Vue from 'vue';
import Component from 'vue-class-component';
import { PropSync } from 'vue-property-decorator';

@Component({})
export default class Pagination extends Vue {
  @PropSync('page') pageSync: number;

  @PropSync('pages') pagesSync: number[];
}
